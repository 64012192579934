.rdrCalendarWrapper {
  position: relative;
  background: transparent;
}

.rdrMonthAndYearWrapper {
  position: absolute;
  width: 100%;
}

.rdrNextPrevButton {
  right: 0;
}

.rdrMonth {
  width: 100%;
}

.rdrMonths {
  margin-top: 8px;
}

.rdrMonthName {
  color: #000000;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
}

.rdrMonthAndYearPickers {
  display: none;
}
