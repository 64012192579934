.white-background {
  background-color: white;
  width: 250px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.title {
  font-size: 13px;
}

.subtitle {
  font-size: 10px;
  opacity: 0.5;
}

.rc-switch {
  border: 1px solid #898e9a;
  background-color: #898e9a;
}

.rc-switch-checked {
  border: 1px solid #00d480;
  background-color: #00d480;
}
