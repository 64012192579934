.add-photo {
  width: 200px !important;
  height: 140px;
}

.slick-list {
  margin: 0 -10px;
}
.slick-list .slick-slide > div {
  padding: 0 10px;
}
