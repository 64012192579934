@font-face {
  font-family: "OpenSans-Regular";
  src: url("./OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-Bold";
  src: url("./OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-BoldItalic";
  src: url("./OpenSans-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("./OpenSans-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-ExtraBoldItalic";
  src: url("./OpenSans-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-Light";
  src: url("./OpenSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-LightItalic";
  src: url("./OpenSans-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-RegularItalic";
  src: url("./OpenSans-RegularItalic.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("./OpenSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-SemiBoldItalic";
  src: url("./OpenSans-SemiBoldItalic.ttf") format("truetype");
}
