.pencil-duplicate {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3063d5;
  opacity: 1;
  padding: 5px;
  border-radius: 5px;
}

.pencil-edit {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3063d5;
  opacity: 1;
  padding: 5px;
  border-radius: 5px;
}

.pencil-validate {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #00d480;
  opacity: 1;
  padding: 5px;
  border-radius: 5px;
}

.pencil-trash {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef2654;
  opacity: 1;
  padding: 5px;
  border-radius: 5px;
}

.react-datepicker__day--highlighted {
  background-color: red !important;
}

.form-control-invalid {
  border-radius: 5px;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.7rem 0.75rem 0.65rem 0.75rem;
  line-height: 1.5;
  border: 1px solid #d7d7d7;
  background: white;
  color: #212121;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 100;
}

.custom-arrow-left {
  border: solid;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-bottom: 1px;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}
@media (min-width: 576px) {
  .modal-dialog-lg {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-sm {
    margin: 1.75rem auto;
  }
  .modal-dialog-xs {
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
}

.Toastify__toast-container {
  padding: 0px;
}

.Toastify__toast-container--top-center {
  top: 0;
}

.Toastify__toast {
  border-radius: 0px;
  font-size: 15px;
  font-family: "OpenSans-SemiBold";
}

.Toastify__toast-body {
  text-align: center;
}
