#HW_badge_cont {
  position: absolute !important;
}
#HW_badge {
  margin-left: 8px !important;
  margin-top: -14px !important;
}

#change-log {
  width: 32px;
  height: 32px;
}
