#booking-list-date-picker {
  .react-datepicker__input-container input {
    color: #3063d5;
    padding: 0 !important;
    margin-left: 0.35rem !important;
    width: 13rem;
  }
  .react-datepicker__navigation {
    top: 22px;
  }
  .react-datepicker__month-container {
    padding: 10px;
    .react-datepicker__day--today {
      color: #898e9a !important;
    }
    .react-datepicker__day {
      color: #898e9a;
      border-radius: 50% !important;
      &:hover {
        color: #ffffff;
      }
    }
    .react-datepicker__day--selected {
      color: #ffffff !important;
    }
    .react-datepicker__day--outside-month {
      color: #cbcdd4;
    }
    .react-datepicker__current-month {
      text-transform: capitalize;
    }
    .react-datepicker__day-names {
      border-bottom: 0.5px solid #efeff2;
      margin-top: 10px;
      .react-datepicker__day-name {
        color: #cbcdd4;
      }
    }
  }
}

#formatted-calendar, #formatted-reporting-date-picker {
  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 0;
    margin-top: 7px;
  }

  .react-datepicker {
    border-color: #ededf5;
    border-radius: 8px;
    width: 266px;
    right: 34px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: #ffffff;
    border: none;
  }

  .react-datepicker__navigation-icon {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background-color: #cbcdd4;
  }

  .react-datepicker__month-container {
    float: initial;
    margin-top: 8px;
  }

  .react-datepicker__current-month {
    margin-top: -2px;
    font-family: "OpenSans-SemiBold";
    font-size: 14px;
  }

  .react-datepicker__navigation {
    top: 11px;
  }

  .react-datepicker__navigation--previous {
    left: 8px;
  }

  .react-datepicker__navigation--next {
    right: 8px;
  }

  .react-datepicker__navigation-icon::before {
    border-color: #000000;
    top: 7px;
  }

  .react-datepicker__navigation-icon--previous::before {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid;
    right: 10px;
    transform: rotate(0);
  }

  .react-datepicker__navigation-icon--next::before {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid;
    left: 10px;
    transform: rotate(180deg);
  }

  .react-datepicker__day-names {
    margin-top: 14px;
    margin-bottom: -12px;
  }

  .react-datepicker__day-name {
    font-family: "OpenSans-Regular";
    font-size: 12px;
    text-align: justify;
    color: #898e9a;
    line-height: 14.5px;
    height: auto;
  }

  .react-datepicker__day {
    width: 22px;
    height: 22px;
    border-radius: 10px !important;
    line-height: 21.5px;
    vertical-align: middle;
    font-family: "OpenSans-SemiBold";
    font-size: 11.7px;
    font-weight: 500;
    color: #212631;
    margin: 1.5px 6px;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__day--outside-month {
    color: #cbcdd4;
  }

  .react-datepicker__day--keyboard-selected {
    color: #212631;
    background-color: #ffffff;
  }

  .react-datepicker__day--today {
    background-color: #3063d580;

    &:hover {
      background-color: #a7bceb;
    }
  }

  .react-datepicker__day--selected {
    color: #ffffff;
    background-color: #3063d5;
  }
}
